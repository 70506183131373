const BussinessScene = {
  HomeTop: 1,
  HomeLeftBottom: 2,
  HomeFloatBtn: 3,
  BugEntrace: 4,
  LQOfficialSite: 5, // 蓝桥官网首页介绍
  LQOfficialSiteWebType: 6, // 蓝桥官网首页介绍-网页型接待，在当前窗口打开
}

const bussinessMap = {
  [BussinessScene.HomeTop]: {
    src: 'https://wp.qiye.qq.com/qidian/3009029022/fdf7c131de1b7a196faae3094c68d687',
    id: 'qd3009029022fdf7c131de1b7a196faae3094c68d687',
    eleId: 'qidian_service_id_1',
    isWebType: false,
  },
  [BussinessScene.HomeLeftBottom]: {
    src: 'https://wp.qiye.qq.com/qidian/3009029022/2ccefe1accc908f17d13e4ad9689fc84',
    id: 'qd30090290222ccefe1accc908f17d13e4ad9689fc84',
    eleId: 'qidian_service_id_2',
    isWebType: false,
  },
  [BussinessScene.HomeFloatBtn]: {
    src: 'https://wp.qiye.qq.com/qidian/3009029022/b8133da2816398de146480164a9fb7a7',
    id: 'qd3009029022b8133da2816398de146480164a9fb7a7',
    eleId: 'qidian_service_id_3',
    isWebType: false,
  },
  [BussinessScene.BugEntrace]: {
    src: 'https://wp.qiye.qq.com/qidian/3009029022/677fb98008e6ee10002a6a234ffeb390',
    id: 'qd3009029022677fb98008e6ee10002a6a234ffeb390',
    eleId: 'qidian_service_id_4',
    isWebType: false,
  },
  [BussinessScene.LQOfficialSite]: {
    src: 'https://wp.qiye.qq.com/qidian/3009029022/5f5152037f5ee0879cf5af71195d9733',
    id: 'qd30090290225f5152037f5ee0879cf5af71195d9733',
    eleId: 'problem-feedback',
    isWebType: false,
  },
  [BussinessScene.LQOfficialSiteWebType]: {
    src: 'https://wp.qiye.qq.com/qidian/3009029022/6992165f9d337c78e267a686d408f92b',
    id: 'qd30090290226992165f9d337c78e267a686d408f92b',
    eleId: 'qidian_service_id_6',
    isWebType: true,
  },
}

export function loadQiDianQQServiceSDK(options) {
  if (!options || !options.bussinessScene) {
    return console.error('缺少业务场景')
  }

  var matchObj = bussinessMap[options.bussinessScene]
  if (!matchObj || !matchObj.eleId) {
    return console.error('该业务场景未注册，请联系开发处理')
  }

  // 如果已经加载过，直接拉起，不用重复加载资源
  if (loadQiDianQQServiceSDK[matchObj.id]) {
    var existingEle = document.getElementById(matchObj.eleId)
    if (existingEle) {
      existingEle.click()
      return
    }
  }

  // 创建新元素
  var divElement = document.createElement('div')
  divElement.setAttribute('id', matchObj.eleId)
  divElement.style.display = 'none'
  document.body.appendChild(divElement)

  // 添加脚本
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = matchObj.src
  script.id = matchObj.id
  script.charset = 'utf-8'
  script.onload = function () {
    loadQiDianQQServiceSDK[matchObj.id] = true
    // 触发点击
    window.__WPAV2 &&
      window.__WPAV2.ready(function (e) {
        // dom型接待-网页类型 及时在 window.__WPAV2.ready 再次触发click，也无法立马拉起，所以需要提前调用init去加载，加载的第一次不去执行后续动作
        if (options.ignoreFirst) {
          return
        }
        var existingEle = document.getElementById(matchObj.eleId)
        existingEle && existingEle.click()
      })
  }
  document.getElementsByTagName('body')[0].appendChild(script)
}

//
loadQiDianQQServiceSDK.init = function (options) {
  options.ignoreFirst = true
  loadQiDianQQServiceSDK(options)
}
